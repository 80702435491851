import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import PageLayout from "../components/PageLayout/PageLayout"

const ErrorImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: allFile(
        filter: { relativePath: { eq: "images/error.jpg" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Img fluid={data.placeholderImage.edges[0].node.childImageSharp.fluid} />
  )
}

const NotFoundPage = () => (
  <PageLayout>
    <section style={{ background: `#000`, color: `#fff` }}>
      <div
        style={{
          maxWidth: `800px`,
          maxHeight: `800px`,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: `4rem`,
          overflow: "hidden",
        }}
      >
        <ErrorImage />
      </div>

      <header style={{ textAlign: "center" }}>
        <h1 style={{ fontSize: `3rem`, fontWeight: 800, marginBottom: `2rem` }}>
          Oops !!! Seems like the link is broken{" "}
        </h1>
        <Link to="/">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            style={{ backgroundColor: `transparent`, borderBlockColor: `#fff` }}
          >
            Back to Home
          </button>
        </Link>
      </header>
    </section>
  </PageLayout>
)

export default NotFoundPage
